import React from 'react';
import Head from 'next/head';
import { Footer } from '@/fe-components/footer';
import { HomepageContainer } from '@/fe-containers/homepage';

export default function DefaultPage() {
  return (
    <>
      <Head>
        <title>{`DigsUp! A new way to buy and sell homes: find > track > snatch!`}</title>
      </Head>
      <HomepageContainer />
      <Footer />
    </>
  );
}
