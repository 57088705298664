import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { Homepage } from '@digsup/templates-homepage';
import { PricingPlans } from '@digsup/patterns-pricing-plans';
import { NavigationContainer } from '../navigation';
import { HomepageSearchContainer } from './containers/homepage-search';

export const HomepageContainer = () => {
  const router = useRouter();
  const [authModalIsOpen, setAuthModalIsOpen] = useState(false);

  return (
    <Homepage targetId={router?.query?.target}>
      <NavigationContainer
        variant="interactive"
        isAuthModalOpen={authModalIsOpen}
        onCloseAuthModal={() => setAuthModalIsOpen(false)}
      />
      <Homepage.Hero>
        <HomepageSearchContainer />
      </Homepage.Hero>
      <Homepage.Features />
      <Homepage.Pricing>
        <PricingPlans.Row>
          <PricingPlans.Card
            type="free"
            onClick={() => setAuthModalIsOpen(true)}
            ctaName="Get Started"
            ctaVariant="primary"
            title="Free"
            price="$0.00"
            priceSuffix="∞ no time limit"
            features={[
              {
                name: 'Dream-home trackers',
                type: 'excluded',
              },
              {
                name: 'Contact owners',
                type: 'excluded',
              },
              {
                name: 'Reply to buyers',
                type: 'included',
              },
              {
                name: `Your home's ratings`,
                type: 'excluded',
              },
              {
                name: '1 Claimed listing',
                type: 'included',
              },
              {
                name: 'Verified listings',
                type: 'excluded',
              },
              {
                name: '∞ Bookmarks',
                type: 'excluded',
              },
              {
                name: '∞ Ratings history',
                type: 'excluded',
              },
            ]}
          />
          <PricingPlans.Card
            type="standard"
            href="mailto:sales@digsup.com"
            ctaName="Contact Sales"
            title="Standard"
            price="$2.50"
            priceSuffix="12 months one time payment of $30"
            features={[
              {
                name: '2 Dream-home trackers',
                type: 'included',
              },
              {
                name: 'Contact owners',
                type: 'included',
              },
              {
                name: 'Reply to buyers',
                type: 'included',
              },
              {
                name: `Your home's ratings`,
                type: 'included',
              },
              {
                name: '1 Claimed listing',
                type: 'included',
              },
              {
                name: 'Verified listing',
                type: 'included',
              },
              {
                name: '∞ Bookmarks',
                type: 'included',
              },
              {
                name: '∞ Ratings history',
                type: 'included',
              },
            ]}
          />
          <PricingPlans.Card
            type="premium"
            href="mailto:sales@digsup.com"
            ctaName="Contact Sales"
            title="Premium"
            price="$7.50"
            priceSuffix="12 months one time payment of $90"
            features={[
              {
                name: '3 Dream-home trackers',
                type: 'included',
              },
              {
                name: 'Contact owners',
                type: 'included',
              },
              {
                name: 'Reply to buyers',
                type: 'included',
              },
              {
                name: `Your home's ratings`,
                type: 'included',
              },
              {
                name: '6 Claimed listings',
                type: 'included',
              },
              {
                name: 'Verified listings',
                type: 'included',
              },
              {
                name: '∞ Bookmarks',
                type: 'included',
              },
              {
                name: '∞ Ratings history',
                type: 'included',
              },
            ]}
          />
        </PricingPlans.Row>
        <PricingPlans.Custom
          href="mailto:sales@digsup.com"
          title="Professional"
          subtitle="617-464-4364"
          label="Design your custom package."
          features={[
            'Active alerts for multiple properties.',
            'Request contact from current owners.',
            'Reporting on property interest.',
          ]}
        />
      </Homepage.Pricing>
      <Homepage.PartnersBanner />
    </Homepage>
  );
};
