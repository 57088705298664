import React, { ComponentType, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { Form } from '@digsup/components-form';
import { AddressSearchBarWithGoogleMapScript } from '@digsup/patterns-map';

import { FindPropertyModalContainerProps } from '../find-property-modal';

const FindPropertyModalContainer: ComponentType<FindPropertyModalContainerProps> =
  dynamic(
    () =>
      import('../find-property-modal').then(m => m.FindPropertyModalContainer),
    {
      ssr: false,
    },
  );

type LocationType = {
  address?: {
    unit?: string;
    unitType?: string;
    formattedAddress?: string;
  };
  googlePlaceId?: string;
};

export const HomepageSearchContainer = () => {
  const [isModalOpen, setIsModaOpen] = useState(false);
  const [location, setLocation] = useState<LocationType>({});

  const onCloseModal = () => {
    setIsModaOpen(false);
    setLocation({});
  };

  useEffect(() => {
    if (location?.address && location?.googlePlaceId) {
      setIsModaOpen(true);
    }
  }, [location?.address, location?.googlePlaceId]);

  const findPropertyModal = isModalOpen ? (
    <FindPropertyModalContainer
      isModalOpen
      onRequestClose={onCloseModal}
      googlePlaceId={location?.googlePlaceId}
      address={location?.address}
    />
  ) : null;

  return (
    <>
      {findPropertyModal}
      <Form
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSubmit={() => { }}
        initialValues={{
          location: '',
        }}>
        {() => (
          <Form.Field name="location">
            {({ form }) => (
              <div className="l-homepage-hero-content__searchbar">
                <AddressSearchBarWithGoogleMapScript
                  onSelect={(googlePlaceId, address) => {
                    const formattedAddress = address?.formattedAddress;
                    if (formattedAddress && googlePlaceId) {
                      form.setFieldValue('location', {
                        googlePlaceId,
                        address: formattedAddress,
                      });
                      setLocation({
                        googlePlaceId,
                        address
                      });
                    }
                  }}
                  value={location?.address?.formattedAddress}
                  smartyStreetsApiKey={
                    process.env.NEXT_PUBLIC_SMARTY_STREET_CLIENT_KEY
                  }
                />
              </div>
            )}
          </Form.Field>
        )}
      </Form>
    </>
  );
};
